<template>
  <div class="row align-items-center">
    <ul class="progressbar horizontal" data-spy="affix" data-offset-top="190">
      <li class="pl-1 active" style="width: 25%;"
      @click="goToUrl('logistics-insurance', true)"
      >
        <span class="indicator">1</span>
        <span class="title">Start</span>
      </li>
      <li
        @click="goToUrl('road-haulier', step >= 2 && roadCarrier)"
        class="pl-2" :class="{ 'disabled': !roadCarrier, 'active': step >= 2 && roadCarrier }" style="width: 25%;">
        <span class="indicator">2</span>
        <span class="title">Straßenfrachtführer</span>
      </li>
      <li
        @click="goToUrl('forwarding-activities', step >= 3 && roadCarrier)"
        class="pl-3" :class="{ 'disabled': !forwardingActivities, 'active': step >= 3 && roadCarrier }" style="width: 25%;">
        <span class="indicator">3</span>
        <span class="title">Speditionelle Tätigkeiten</span>
      </li>
      <li
        @click="goToUrl('watchman', step >= 4 && roadCarrier)"
        class="pl-4" :class="{ 'disabled': !watchman, 'active': step >= 4 && roadCarrier }" style="width: 25%;">
        <span class="indicator">4</span>
        <span class="title">Lagerhalter</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'progress-bar',
  props: ['roadCarrier', 'forwardingActivities', 'watchman', 'step'],
  methods: {
    goToUrl(goPath, active) {
      if (active) {
        this.$router.push({
          name: goPath,
        }).catch((err) => {
          throw new Error(`Problem handling something: ${err}.`);
        });
      }
    },
  },
});
</script>

<style scoped>
  .progressbar li.active .indicator, .progressbar li.active .title {
    cursor: pointer;
  }
  .progressbar li.active .indicator, .progressbar li.active .title {
    cursor: pointer;
  }
</style>
